
import Vue from 'vue';
import { mapGetters } from 'vuex';
import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import { Models, Services } from '@/injectables/tokens';
import { BudgetModelContract, CalendarServiceContract, ProposalProductModelContract } from '@/injectables';
import { ProductConfigCategory } from '@/app/graphql';

export default Vue.extend({
  name: 'summaryProductView',
  useInjectable: [Models.Budget, Services.Calendar, Models.ProposalProduct],

  components: { WrapperWithTooltip },
  props: {
    product: Object,
  },
  methods: {
    openOptionConfig() {
      this.enableOptionsConfig = true;
    },
    productIconAndColor() {
      const category = this.product?.category || 'display';
      return (this[Models.ProposalProduct] as ProposalProductModelContract).categoryIconAndColor(category);
    },
    kpi(product = this.product): number | string {
      return (this[Models.ProposalProduct] as ProposalProductModelContract).productKPI(product);
    },
    keyMetricName(product = this.product): string {
      return (this[Models.ProposalProduct] as ProposalProductModelContract).productKeyMetric(product);
    },
    settingDotColor(product = this.product): string {
      let color = 'grey lighten-2';
      if (product?.name && this.productIsConfigured(this.product)) {
        color = 'success';
      } else if (product?.name) {
        color = 'warning';
      } // else if (errorStatus) color = 'error'?
      return color;
    },
    getMonthlySpend(product): number {
      const budget = product?.budget || 0;
      const { startDate, endDate } = this.$store.state.newProposal.newProposal;

      return (this.budgetEntity as BudgetModelContract).getMonthlySpend({
        budget,
        startDate,
        endDate,
        calendarService: this.calendarService as CalendarServiceContract,
      });
    },
    productIsConfigured(product) {
      return (this[Models.ProposalProduct] as ProposalProductModelContract).productIsConfigured(product);
    },
    getReadableCategory(category) {
      return (this[Models.ProposalProduct] as ProposalProductModelContract).getReadableCategory(category);
    },
  },
  computed: {
    isXMLProduct(): boolean {
      return (this[Models.ProposalProduct] as ProposalProductModelContract).isXMLProduct(this.product);
    },
    isNoXML(): boolean {
      const proposalProductEntity: ProposalProductModelContract = this[Models.ProposalProduct];

      return this.isPackage && this.isXMLProduct && proposalProductEntity.noXmlConfiguredProduct(this.product);
    },
    isPaidSearch(): boolean {
      return (this[Models.ProposalProduct] as ProposalProductModelContract).isPaidSearchProduct(this.product);
    },
    selectedProposalId(): string {
      return this.$store.state['newProposal'].newProposal.id;
    },
    isPackage() {
      return this.product?.category === ProductConfigCategory.Package;
    },
  },
  data: (): {
    enableOptionsConfig: boolean;
  } => ({
    enableOptionsConfig: false,
  }),
});
