
import Vue from 'vue';
import debounce from 'lodash.debounce';
import MapComponent from '@/shared/legacy/map/map.vue';
import { UnsafeAny } from '@/shared/types';
import { Services } from '@/injectables/tokens';
import { LoggerContract } from '@/injectables';

export default Vue.extend({
  name: 'proposalSummaryMap',

  useInjectable: [Services.Polygons],

  components: { MapComponent },

  props: {
    zipList: {
      type: Array,
      default: () => [],
    },
    cityList: {
      type: Array,
      default: () => [],
    },
    countyList: {
      type: Array,
      default: () => [],
    },
    dmaList: {
      type: Array,
      default: () => [],
    },
    stateList: {
      type: Array,
      default: () => [],
    },
  },

  data: (): {
    showMap: boolean;
    mapHeight: number;
    loading: boolean;
    loadedPolygons: UnsafeAny;
    selectedPolygons: [];
    mapReady: boolean;
  } => ({
    showMap: false,
    mapHeight: 0,
    loading: false,
    loadedPolygons: null,
    selectedPolygons: [],
    mapReady: false,
  }),

  watch: {
    geoSelections: {
      handler() {
        if (this.mapReady) this.onMapReady();
      },
      deep: true,
    },
  },

  computed: {
    selectedMap(): string {
      // '381cd47b-1090-4580-b7bb-7058f89205f5'
      return this.$store.state.map.defaultMap;
    },
    geoSelections(): object {
      return {
        zips: this.zipList,
        cities: this.cityList,
        dmas: this.dmaList,
        states: this.stateList,
        counties: this.countyList,
      };
    },
  },

  mounted() {
    window.addEventListener('optimizedResize', this.onWindowResize);
    this.$nextTick(() => {
      this.showMap = true; // let the container render first, to get its height
      this.mapHeight = this.$refs.mapContainer?.offsetHeight || 0;
    });
  },

  beforeDestroy() {
    window.removeEventListener('optimizedResize', this.onWindowResize);
  },

  methods: {
    simpleAdjustMap() {
      this.mapHeight = this.$refs.mapContainer?.offsetHeight || 0;
    },
    adjustMap: debounce(function () {
      this.showMap = false; // remove map to let container figure out its new max height
      setTimeout(() => {
        this.mapHeight = this.$refs.mapContainer?.offsetHeight || 0;
      }, 500);
      setTimeout(() => {
        this.showMap = true;
      }, 1000);
      setTimeout(() => {
        const map = this.$refs.proposalSummaryMap?.Get();
        if (map) {
          map.Redraw();
        }
      }, 1100);
      setTimeout(() => {
        this.renderPolygons();
      }, 2000);
    }, 500),
    onWindowResize(): void {
      this.showMap = false;
      this.adjustMap();
    },
    hasSelectedGeo(): boolean {
      const notEmpty = item => Array.isArray(item) && item.length;
      return [this.zipList, this.cityList, this.dmaList, this.countyList, this.stateList].some(notEmpty);
    },
    async onMapReady(): Promise<void> {
      this.simpleAdjustMap();
      this.mapReady = true;
      const map = this.$refs.proposalSummaryMap?.Get();
      if (!map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map);
        return;
      }

      map.ClearMap();

      if (this.hasSelectedGeo()) {
        this.loading = true;
        const { isErr, unwrap, unwrapErr } = await this.polygonsService.getPolygons(this.geoSelections);
        this.loading = false;
        if (isErr()) {
          const { message = '' } = unwrapErr();
          const logger: LoggerContract = this.$container.get('logger');
          logger.print('error', 'ProposalSummaryMap', message);
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
        const { pstate, ...polygons } = unwrap();
        this.loadedPolygons = polygons;
        this.renderPolygons();
      } else {
        map.SetView([40, -100], 4);
      }
    },
    renderPolygons(): void {
      const map = this.$refs.proposalSummaryMap?.Get();
      if (!map || !map.host || !map.leaflet) {
        // eslint-disable-next-line no-console
        console.error('map component did not load correctly', map);
        return;
      }

      map.RemoveLayer('allPolygons');
      const polyLayer = map.CreateLayer('allPolygons');

      Object.keys(this.loadedPolygons).forEach((key: string) => {
        if (key !== 'LocationNotFound') {
          const polygon = this.loadedPolygons[key];
          const layer = map.AddToLayer(polyLayer);
          map.SetWKT(layer, polygon.WKT_P100, true);

          const polyStyle = {
            fillOpacity: 0.4,
            color: this.$vuetify.theme.themes.light.primary,
            fillColor: this.$vuetify.theme.themes.light.primary,
            weight: 3,
          };
          layer.setStyle(polyStyle);
        }
      });

      map.FitAllLayers();
    },
  },
});
