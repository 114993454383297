
import Vue from 'vue';
import { ConfiguredProduct } from '@/shared/types';
import { OutputSlide } from '@/shared/legacy/classes';

import { BudgetModelContract, OutputServiceContract, CalendarServiceContract, DateModelContract } from '@/injectables';
import { Models, Services } from '@/injectables/tokens';

import ProposalSummaryMap from '@/components/ProposalSummaryMap.vue';
import Product from '@/components/Proposal/SummaryProductView.vue';
import WrapperWithTooltip from '@/components/WrapperWithTooltip.vue';
import Slide from '@/components/Output/Slide.vue';

export default Vue.extend({
  name: 'ProposalSummary',

  components: { ProposalSummaryMap, Product, WrapperWithTooltip, Slide },

  useInjectable: [Models.Budget, Services.Calendar, Services.Env, Models.Date],

  data: (): {
    previewFailure: boolean;
    mapHeight: number;
    pos: {
      top: number;
      y: number;
    };
    draggable: boolean;
    previewLoading: boolean;
    previewSlide: OutputSlide;
    colorScheme: any;
  } => ({
    previewLoading: false,
    previewFailure: false,
    mapHeight: 0,
    pos: { top: 0, y: 0 },
    draggable: false,
    previewSlide: null,
    colorScheme: null,
  }),

  filters: {
    prettyValue(value): string {
      return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';
    },
  },

  computed: {
    proposalId() {
      const { proposalId } = this.$route.params;
      return proposalId;
    },
    clientId() {
      const { id } = this.$route.params;
      return id;
    },
    proposalStartDate(): Date | string | null {
      if (!this.newProposal?.startDate) return null;
      return (this[Models.Date] as DateModelContract).dateToFormatDateString(this.newProposal.startDate) || null;
    },
    proposalEndDate(): Date | string | null {
      if (!this.newProposal?.endDate) return null;

      return (this[Models.Date] as DateModelContract).dateToFormatDateString(this.newProposal.endDate) || null;
    },
    geoSelections(): Object {
      const newProposal = this.$store.state.newProposal.newProposal;
      return newProposal.market.geoSelections;
    },
    newProposal() {
      return this.$store.state.newProposal.newProposal;
    },
    selectedProducts(): ConfiguredProduct[] {
      return this.newProposal?.products.filter(p => p?.name) || [];
    },
    targetedDemographics(): number {
      return this.$store.getters['newProposal/targetedPopulation'];
    },
    proposalOutput(): Object {
      if (this.proposalId && this.clientId) {
        return { name: 'proposalOutput', params: { id: this.clientId, proposalId: this.proposalId } };
      }
      return {};
    },
    client() {
      return this.$store.state.client?.activeClient || {};
    },
  },

  methods: {
    getMonthlyBudget(): number {
      const { startDate, endDate, budget = 0 } = this.newProposal;

      return (this.budgetEntity as BudgetModelContract).getMonthlySpend({
        budget,
        startDate,
        endDate,
        calendarService: this.calendarService as CalendarServiceContract,
      });
    },
    step(name): object {
      const steps = {
        market: { name: 'proposalMarket', params: this.$route.params },
      };
      return steps[name];
    },
    async getScreenshotUrl(): Promise<void> {
      const { proposalId } = this.$route.params;

      const outputService: OutputServiceContract = this.$container.get(Services.Output);

      this.previewLoading = true;
      const { isErr, unwrap, unwrapErr } = await outputService.getOutputPreview(proposalId);
      this.previewLoading = false;

      if (isErr()) {
        const { message } = unwrapErr();
        this.previewLoading = false;
        this.previewFailure = true;
        // eslint-disable-next-line no-console
        console.error(message);
        return;
      }

      const { slide, colorScheme } = unwrap();

      this.previewSlide = slide;
      this.colorScheme = colorScheme;

      return;
    },
    mouseDownHandler(e) {
      const ele = document.getElementById('products-list');
      if (ele) {
        this.draggable = true;

        ele.style.cursor = 'grabbing';
        ele.style.userSelect = 'none';

        this.pos = {
          top: ele.scrollTop,
          y: e.clientY,
        };
      }
    },
    mouseMoveHandler(e) {
      const ele = document.getElementById('products-list');
      if (ele && this.draggable) {
        // How far the mouse has been moved
        const dy = e.clientY - this.pos.y;

        // Scroll the element
        ele.scrollTop = this.pos.top - dy;
      }
    },
    mouseUpHandler() {
      const ele = document.getElementById('products-list');
      if (ele) {
        this.draggable = false;
        ele.style.cursor = 'grab';
        ele.style.removeProperty('user-select');
      }
    },
    mouseLeaveHandler() {
      const ele = document.getElementById('products-list');
      if (ele) {
        this.draggable = false;
        ele.style.cursor = 'grab';
      }
    },
  },

  beforeRouteLeave(to, from, next): void {
    this.$store.dispatch('output/resetOutputAndProposal', {
      routeName: to.name,
    });
    next();
  },

  mounted(): void {
    this.getScreenshotUrl();
  },
});
